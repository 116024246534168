import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { roundAccurately } from "../utils/math";

interface CalculatedRow {
  betNumber: number;
  amountBet: number;
  sumOfBets: number;
  percentTotal: number;
  bankrollRemaining: number;
  winAmount: number;
  profit: number;
  losing_streak_odds: number;
  profitable: boolean;
}
interface CalculatorState {
  bankroll: number;
  betAmount: number;
  houseEdge: number;
  chance: number;
  calculatedPayout: number;
  raiseOnLoss: number;
  rows: CalculatedRow[];
}
class Calculator extends React.Component {
  state: CalculatorState = {
    bankroll: 0.001,
    betAmount: 0.000001,
    houseEdge: 2,
    chance: 50,
    calculatedPayout: 1.96,
    raiseOnLoss: 100,
    rows: [],
  };
  constructor(props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
  }

  componentDidMount() {
    this.recalculate();
  }
  recalculate() {
    const payout = ((100 - this.state.houseEdge) / this.state.chance).toFixed(
      4
    );
    this.setState({ calculatedPayout: payout });

    this.calculateTableRows();
  }

  private calculateTableRows() {
    if (
      this.state.chance > 0 &&
      this.state.raiseOnLoss > 0 &&
      this.state.bankroll > 0 &&
      this.state.betAmount > 0
    ) {
      const curRaise = 1 + this.state.raiseOnLoss / 100;
      let numberOfBets = 1;
      let win = 0;
      let betSum = 0;
      let profit = 0;
      let bankPercent = 0;
      let currentBankroll = this.state.bankroll;
      let currentBet = this.state.betAmount;
      let amountRemaining = this.state.bankroll;
      let odds = 100 - this.state.chance;
      const tableRows: CalculatedRow[] = [];
      console.log(currentBet);
      console.log(roundAccurately(currentBankroll - currentBet, 8));
      while (currentBankroll > currentBet) {
        //calculate new bankroll
        currentBankroll = roundAccurately(currentBankroll - currentBet, 8);
        betSum = betSum + currentBet;
        bankPercent = (betSum / this.state.bankroll) * 100;
        amountRemaining = amountRemaining - betSum;
        win = currentBet * this.state.calculatedPayout;
        console.log(currentBet * this.state.calculatedPayout);
        profit = win - betSum;

        tableRows.push({
          betNumber: numberOfBets,
          amountBet: currentBet,
          sumOfBets: betSum,
          percentTotal: bankPercent,
          bankrollRemaining: amountRemaining,
          winAmount: win,
          profit: profit,
          losing_streak_odds: odds,
          profitable: profit > 0,
        });
        numberOfBets += 1;
        currentBet = currentBet * curRaise;
        odds = (odds * (100 - this.state.chance)) / 100;
      }
      console.log(tableRows);
      this.setState({ rows: tableRows });
    }
  }

  onValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(event.target);
    this.setState({ [event.target.name]: event.target.value });
    this.recalculate();
  }

  createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
  ) {
    return { name, calories, fat, carbs, protein };
  }

  render() {
    return (
      <div
        style={{
          alignContent: "center",
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        <Grid container>
          <Grid item component={Card} md={2}>
            <Card>
              <CardHeader title="Set your parameters" />
              <CardContent>
                <TextField
                  label="Bankroll"
                  id="bankroll"
                  name="bankroll"
                  value={this.state.bankroll}
                  onChange={this.onValueChange}
                />
                <TextField
                  label="Bet Amount"
                  id="bet-amount"
                  name="betAmount"
                  value={this.state.betAmount}
                  onChange={this.onValueChange}
                />
                <TextField
                  label="% Chance to win"
                  id="chance-to-win"
                  type="number"
                  name="chance"
                  value={this.state.chance}
                  onChange={this.onValueChange}
                />
                <Typography variant="subtitle1" color="textPrimary">
                  Payout: {this.state.calculatedPayout}
                </Typography>
                <TextField
                  label="Raise bet on Loss"
                  id="raise-bet"
                  type="number"
                  name="raiseOnLoss"
                  value={this.state.raiseOnLoss}
                  onChange={this.onValueChange}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid component={Card} item md={9}>
            <Card>
              <CardContent>
                <TableContainer>
                  <Table
                    aria-label="simple table"
                    style={{
                      marginLeft: "10rem",
                      width: "auto",
                      tableLayout: "auto",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width="70px">Roll #</TableCell>
                        <TableCell align="left">Bet Amount</TableCell>
                        <TableCell align="right">Sum of Bets</TableCell>
                        <TableCell align="right" width="20px">
                          % of total
                        </TableCell>
                        <TableCell align="right">Remaining Amount </TableCell>
                        <TableCell align="right">Amount Won </TableCell>
                        <TableCell align="right">Profit</TableCell>
                        <TableCell align="right">
                          Odd of losing streak
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row) => (
                        <TableRow key={row.betNumber}>
                          <TableCell scope="row">{row.betNumber}</TableCell>
                          <TableCell scope="row">
                            {row.amountBet.toFixed(8)}
                          </TableCell>
                          <TableCell align="right">
                            {row.sumOfBets.toFixed(8)}
                          </TableCell>
                          <TableCell align="right">
                            {roundAccurately(row.percentTotal, 2)}%
                          </TableCell>
                          <TableCell align="right">
                            {row.bankrollRemaining.toFixed(8)}
                          </TableCell>
                          <TableCell align="right">
                            {row.winAmount.toFixed(8)}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              backgroundColor: row.profitable
                                ? "#2a9d8f"
                                : "#e76f51",
                            }}
                          >
                            {row.profit.toFixed(8)}
                          </TableCell>
                          <TableCell align="right">
                            {roundAccurately(row.losing_streak_odds, 8)}%
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Calculator;
