import React, { FC } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Grid, Button, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import SEO from "../components/seo";
import Hero from "../components/hero";
import Calculator from "../components/calculator";
const useStyles = makeStyles((theme: Theme) => ({
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

const IndexPage: FC = () => {
  const classes = useStyles();
  return (
    <>
      <SEO
        title="Crypto Dice Odds Calculator"
        description="this crypto and bitcoin dice calculator can be used to create martingale strategies for games.  Maximize profit or mining value per roll"
      />
      <Hero
        title="Crypto Dice Odds Calculator"
        description="Use this calculator can be used to create martingale strategies for dice games or other games based on odds."
      >
        <div className={classes.heroButtons}></div>
      </Hero>
      <Calculator />
    </>
  );
};

export default IndexPage;
