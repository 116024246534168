import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Container, Theme, Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

export interface HeroProps {
  title: string;
  description?: string;
}

const Hero: FC<HeroProps> = ({ title, description = "", children }) => {
  const classes = useStyles();
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          {description}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          <b>Bankroll:</b> Enter the max amount you want to lose
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          <b>Bet Amount:</b> This is the starting bet amount{" "}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          <b>% Chance to Win:</b> This is the winnning percent for dice.
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          <b>Payout: </b> Multiplier of bet amount to be paid based on 2% house
          edge
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          <b>Raise bet on loss:</b> Percentage to increase bet on a loss
        </Typography>
      </Container>
    </div>
  );
};

export default Hero;
